import React from 'react';

const TableHeaderCell = ({ text }) => (
  <p className="col-6 p-6 fw-700 nm">{text}</p>
);

const TableCell = ({ className, text }) => (
  <p className={`bulk-buy-table__cell col-6 p-6 nm ${className}`}>{text}</p>
);

const TableRow = ({ quantity, price }) => (
  <div className="bulk-buy-table__row col-12 grid-no-gap row bb b-colour--grey">
    <TableCell className="fw-700" text={`${quantity}+`} />
    <TableCell text={`£${price}`} />
  </div>
);

const BulkBuyTable = ({ productBreaks }) => (
  <div
    className="product-bulk-buy__table grid-no-gap row col-12 width-100 height-fc p-5
      b-full b-colour--grey bgc--white br-4 fs-14">
    <div className="bulk-buy-table__header grid-no-gap row col-12 bgc--grey">
      <TableHeaderCell text="Qty" />
      <TableHeaderCell text="Unit Price" />
    </div>
    {productBreaks.map(({ per_pack: quantity, per_price: price }) => (
      <TableRow key={quantity} quantity={parseInt(quantity)} price={price} />
    ))}
  </div>
);

export default BulkBuyTable;
