import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import usePricingStore from 'stores/pricingStore/usePricingStore';
import { useCookie } from 'hooks/useCookies';
import { createItemData } from 'utils/createItemData';
import LoadingSpinnerComponent from 'components/shared/LoadingSpinnerComponent';
import ProductStock from 'components/ProductStock';
import AddToFavourites from 'components/products/show/AddToFavourites';
import { pushToDataLayer } from 'components/shared/PushToDatalayer';
import BulkBuyTable from 'components/shared/BulkBuyTable';
import AddToCompare from 'components/ProductCompare/AddToCompare';
import { safeDecode } from '../../../helpers/utils';

const Product = ({ config, data, isProductList, viewType, index }) => {
  const {
    showCatalogue,
    showDescriptionBrand,
    showAddToBasket,
    showCompare,
    showPrice,
    showVat,
    signedIn,
    hideQuantityButtons,
  } = config;
  const {
    catalogue,
    image_medium: imageMedium,
    brand,
    brand_slug: brandSlug,
    id,
    url,
    description,
    stock,
    Features,
    category_descriptions: categoryDescriptions,
    minimum_order: minimumOrder,
  } = data;

  const { productListPrices } = usePricingStore(state => ({
    productListPrices: state.productListPrices,
  }));
  const [price, setPrice] = useState('');
  const [productRrp, setProductRrp] = useState('');
  const [bulkSavingsAvailable, setBulkSavingsAvailable] = useState(false);
  const [bulkSavings, setBulkSavings] = useState();
  const { component, onProductList, buttonText, basketId } = showAddToBasket;
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);
  const bulkTableElement = useRef(null);
  const imageElement = useRef(null);

  const itemData = createItemData({
    index: index + 1,
    currency: 'GBP',
    id,
    catalogue,
    description,
    brand,
    categoryDescriptions,
    rrp: productRrp,
    quantity: minimumOrder,
    itemListId: isProductList ? 'Product List' : 'product_page',
    itemListName: isProductList ? 'product_list' : 'Product Page',
    price,
  });

  // Correctly format product feature data
  const sanitizedProductFeatures = safeDecode(Features).split(/\r?\n/);

  const compareProductInfo = {
    id,
    description,
    imgUrl: imageMedium,
    url,
  };

  useEffect(() => {
    if (productListPrices) {
      const currentProductPrice = productListPrices[id];

      if (currentProductPrice) {
        const {
          cheapest_price: productCheapestPrice,
          breaks: { data: bulkSavingsData },
          rrp,
        } = currentProductPrice;

        setPrice(productCheapestPrice);
        setProductRrp(rrp);
        setBulkSavingsAvailable(bulkSavingsData.length > 0);
        setBulkSavings(bulkSavingsData);
      }
    }
  }, [productListPrices]);

  const fullSize =
    imageMedium === ':placeholder'
      ? 'http://via.placeholder.com/400x400'
      : imageMedium;

  const AddToBasket = component || undefined;

  const viewProduct = () => {
    pushToDataLayer('select_item', itemData);
    window.location.href = url || `/products/${id}`;
  };

  const viewBulkTable = () => {
    bulkTableElement.current.classList.toggle('d-none');
    imageElement.current?.classList.toggle('op-10');
  };

  // Grid View
  const GridViewProduct = () => (
    <div
      className="product col-12 m-col-3 fl fl-col jc-sb p-16 box-hover"
      data-testid="grid-view__product">
      {signedIn && (
        <div className="product__user-actions fl ai-c jc-sb">
          <AddToCompare product={compareProductInfo} title="Compare" />
          <AddToFavourites product={data} url={`${url}/favourite`} />
        </div>
      )}

      {bulkSavingsAvailable && (
        <div ref={bulkTableElement} className="width-100 pos-rel d-none">
          <BulkBuyTable productBreaks={bulkSavings} />
        </div>
      )}

      <div className="product-details fl fl-col ai-fs">
        <Button
          className="nm np b-none bg-none"
          handleClick={e => {
            e.preventDefault();

            viewProduct();
          }}>
          <img
            ref={imageElement}
            src={fullSize}
            className="product-image width-100"
            alt={description}
          />
        </Button>
        {brand && (
          <p className="mtb-5 fs-14 c--blue">
            <a href={`/catalogue/products/search?brand=${brandSlug}`}>
              {brand}
            </a>
          </p>
        )}
        <Button
          className="product__title nm np mtb-12 ta-l bg-none fs-14 fw-700 b-none"
          handleClick={e => {
            e.preventDefault();
            viewProduct();
          }}>
          <span
            className="product__title-link c--black-blue ff--body"
            data-testid="grid-view__product-title-link">
            {showDescriptionBrand && `${brand} `}
            {description}
          </span>
        </Button>
      </div>
      <div className="product-details fl fl-col ai-fs">
        {showCatalogue && (
          <div
            className="product__catalogue mb-5 fs-14 ff--body"
            data-testid="grid-view__product-part-code">
            <span className="product__catalogue__text">Part Code </span>
            <span className="product__catalogue__value fw-700">
              {catalogue}
            </span>
          </div>
        )}
        {parsedCookieValue && signedIn && (
          <div className="product__pricing-container fl jc-sb width-100">
            {price ? (
              <div className="fl jc-sb ai-c width-100">
                <p className="fs-24 fw-700 mtb-12">{price}</p>
                {bulkSavingsAvailable && (
                  <Button
                    className="bulk-discount-reduction bulk-discount-reduction__value
                    fl p-8 br-4 c--blue fs-14"
                    handleClick={() => viewBulkTable()}>
                    Multi-rate
                  </Button>
                )}
              </div>
            ) : (
              <LoadingSpinnerComponent className="fl fl-1 mt-24 mb-24" />
            )}
          </div>
        )}
        {showCompare ||
          (showAddToBasket && (
            <div className="product-basket-actions row">
              {showAddToBasket && onProductList && signedIn && (
                <>
                  <ProductStock
                    className="col-12 m-0 fl ai-c"
                    stockLevel={stock}
                    availabilityDate="12"
                    size="medium"
                  />
                  <AddToBasket
                    stockLevel={stock}
                    isProductList={isProductList}
                    title={buttonText}
                    productId={id}
                    basketId={basketId}
                    showPrice={showPrice}
                    searchView
                    product={data}
                    price={price}
                    itemData={itemData}
                    fieldName="quantity-input"
                    showVat={showVat}
                    hideQuantityButtons={hideQuantityButtons}
                  />
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );

  // List View
  const ListViewProduct = () => (
    <>
      <div className="product list-view-product row col-12 m-col-12 p-16 box-hover">
        <Button
          className="col-3 nm np b-none bg-none"
          handleClick={e => {
            e.preventDefault();
            viewProduct();
          }}>
          <img
            src={fullSize}
            className="product-image"
            alt={description}
            width="180"
            height="180"
          />
        </Button>
        <div
          className="product-details col-5 fl fl-col ai-fs"
          data-testid="list-view__product">
          {brand && (
            <p className="mtb-5 fs-14 c--blue">
              <a href={`/catalogue/products/search?brand=${brandSlug}`}>
                {brand}
              </a>
            </p>
          )}
          <Button
            className="product__title nm np mb-10 ta-l bg-none fs-20 fw-700 b-none"
            handleClick={e => {
              e.preventDefault();
              viewProduct();
            }}>
            <span
              className="c--black-blue ff--body"
              data-testid="list-view__product-title-link">
              {showDescriptionBrand && `${brand} `}
              {description}
            </span>
          </Button>
          {showCatalogue && (
            <div
              className="product__catalogue fs-14 ff--body"
              data-testid="list-view__product-part-code">
              <span className="product__catalogue__text">Part Code </span>
              <span className="product__catalogue__value fw-700">
                {catalogue}
              </span>
              <ul className="product__catalogue__text pl-13">
                {sanitizedProductFeatures.map(feature => (
                  <li key={feature} className="pb-10">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {showCompare ||
          (showAddToBasket && (
            <div className="product-basket-actions col-4 fl fl-col">
              {parsedCookieValue && signedIn && (
                <div className="product__pricing-container fl ai-fs">
                  {price ? (
                    <div className="fl ai-c jc-sb width-100">
                      <div className="fl ai-c width-100">
                        <p className="fs-24 fw-700 m-0 mr-5">{price}</p>
                        {bulkSavingsAvailable && (
                          <div className="pos-rel width-100">
                            <Button
                              className="bulk-discount-reduction bulk-discount-reduction__value
                              fl p-8 br-4 c--blue fs-14 pos-rel"
                              handleClick={() => viewBulkTable()}>
                              Multi-rate
                            </Button>
                            {bulkSavingsAvailable && (
                              <div
                                ref={bulkTableElement}
                                className="list-view__bulk-buy-container d-none">
                                <BulkBuyTable productBreaks={bulkSavings} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <AddToFavourites
                        product={data}
                        url={`${url}/favourite`}
                      />
                    </div>
                  ) : (
                    <LoadingSpinnerComponent className="fl fl-1 jc-fe" />
                  )}
                </div>
              )}
              {showAddToBasket && onProductList && signedIn && (
                <>
                  <ProductStock
                    className="width-auto ai-c mb-10 mt-16"
                    stockLevel={stock}
                    size="medium"
                  />
                  <AddToBasket
                    stockLevel={stock}
                    isProductList={isProductList}
                    isListView
                    title={buttonText}
                    productId={id}
                    basketId={basketId}
                    showPrice={showPrice}
                    searchView
                    product={data}
                    price={price}
                    fieldName="quantity-input"
                    showVat={showVat}
                  />
                </>
              )}
              {signedIn && (
                <AddToCompare
                  product={compareProductInfo}
                  title="Compare"
                  className="mt-14"
                />
              )}
            </div>
          ))}
      </div>
      <div className="col-12 tb-divider" />
    </>
  );

  return viewType === 'grid' ? <GridViewProduct /> : <ListViewProduct />;
};

export default Product;
